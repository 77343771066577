import { viewNotifications } from 'api/notifications'
import { useInfiniteUserNotifications } from 'hooks/useUserNotifications'
import { useMutation, useQueryClient } from 'react-query'

export function useMarkNotificationViewed() {
  useInfiniteUserNotifications()
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: async (notificationIds: number[]) => {
      return viewNotifications({
        notificationIds,
        view: true,
      })
    },
    async onSuccess(data) {
      const { error, success } = data

      if (error) {
        throw new Error(error.message)
      }

      if (!success) {
        throw new Error('An error occured')
      }

      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: 'notifications',
        }),
        queryClient.invalidateQueries({ queryKey: 'infinite-notifications' }),
      ])
    },
  })

  return { viewNotifications: mutateAsync, ...rest }
}
