import { Actions, ActionsState } from 'components/common/Actions'
import { AuthActions } from 'components/common/AuthActions'
import { Icon } from 'components/common/Icon'
import { Logo } from 'components/common/Logo'
import { MobileMenu } from 'components/common/MobileMenu'
import { Separator } from 'components/common/Separator'
import { StaticIcon } from 'components/common/StaticIcon'
import { NavMenu } from 'components/nav/NavMenu'
import routes from 'constants/routes'
import { useFeatureFlags } from 'hooks'
import { useUserNotifications } from 'hooks/useUserNotifications'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useAuth, useDrawer } from '../../contexts'
import { saveScroll } from 'utils/scroll'

export type HeaderProps = {
  className?: string
  hasNav?: boolean
  actions?: ActionsState
  hasSeparator?: boolean
  offWhite?: boolean
  withNotifications?: boolean
}

export const Header = ({
  className,
  hasNav,
  actions,
  hasSeparator = false,
  offWhite = false,
  withNotifications = true,
}: HeaderProps) => {
  const { t } = useTranslation('common')
  const { setIsOpen, setConfig } = useDrawer()
  const { isAuthenticated } = useAuth()

  const {
    data: { notViewedCount },
  } = useUserNotifications()
  const { featureFlags } = useFeatureFlags()

  const displayNotifications =
    featureFlags.notification && isAuthenticated && withNotifications

  return (
    <>
      <header
        className={`container hidden h-16 items-center lg:flex lg:h-20 ${
          actions?.includes('faq') && 'justify-between'
        } ${className}`}
      >
        <Logo />

        {actions && <Actions state={actions} offWhite={offWhite} />}

        {hasNav && <NavMenu className='mr-2 hidden lg:flex' />}

        {actions === 'auth' && <AuthActions />}
      </header>

      <header
        className={`container flex h-16 items-center justify-between lg:hidden ${
          actions?.includes('faq') && 'justify-between'
        } ${className}`}
      >
        {hasNav && (
          <button
            type='button'
            className='flex'
            data-e2e='main-side-menu-button'
          >
            <span className='sr-only'>{t('toggle_navigation_menu')}</span>

            <StaticIcon
              name='hamburgerMenu'
              className='cursor-pointer text-cool-500'
              size={25}
              onClick={() => {
                setConfig?.({
                  direction: 'left',
                  heading: <Logo />,
                  body: (
                    <MobileMenu
                      actions={actions === 'auth' && <AuthActions isMobile />}
                    />
                  ),
                })
                setIsOpen(true)
              }}
            />
          </button>
        )}

        <Logo />

        <div className='flex items-center justify-center space-x-3'>
          {displayNotifications && (
            <Link
              href={routes.notifications}
              className='relative'
              onClick={() => saveScroll({ redirectedFrom: 'homepage' })}
            >
              <p className='sr-only'>View notifications</p>
              <Icon name='bell-2' size={20} className='text-cool-500' />
              {Boolean(notViewedCount) && (
                <div className='absolute right-0 top-0 h-[0.6875rem] w-[0.6875rem] rounded-full border border-cool-50 bg-danger-dark' />
              )}
            </Link>
          )}

          {actions && <Actions state={actions} offWhite={offWhite} />}
        </div>
      </header>

      {hasSeparator && <Separator color='cool-300' />}
    </>
  )
}
