import { ChildrenWithProps } from 'components/abstract/ChildrenWithProps'
import routes from 'constants/routes'
import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  className?: string
  children?: React.ReactNode
  vertical?: boolean
  listClassName?: string
}

export const Nav = ({
  vertical,
  className,
  children,
  listClassName,
}: Props) => {
  const router = useRouter()

  function isChildActive(child: React.ReactElement) {
    if (!child.props.href) return false

    if (child.props.href !== routes.sell && child.props.exact)
      return child.props.href === router.asPath

    return (
      !router.asPath.includes(routes.myAccount.base) &&
      router.asPath.includes(child.props.href)
    )
  }

  function isChildActiveTag(child: React.ReactElement) {
    return (
      child.props.href &&
      child.props.href !== routes.sell &&
      router.asPath.includes(child.props.href) &&
      router.asPath.includes('/my-account/')
    )
  }

  const onChildClone = (child: React.ReactElement) => {
    return React.cloneElement(child, {
      active: isChildActive(child),
      activeTag: isChildActiveTag(child),
      hasArrowIcon: vertical && child.props.href,
    })
  }

  return (
    <nav className={className}>
      <ul
        className={`flex
          ${vertical ? 'flex-col space-y-3' : 'items-center space-x-2'}
          ${listClassName}
        `}
      >
        <ChildrenWithProps
          props={{ hasArrowIcon: vertical }}
          onChildClone={onChildClone}
        >
          {children}
        </ChildrenWithProps>
      </ul>
    </nav>
  )
}
