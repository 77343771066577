type RedirectedFron = 'artworks' | 'artists' | 'artist' | 'homepage'

const sessionStorageKeys = {
  scrollPosition: 'scrollPosition',
  page: 'page',
  redirectedFrom: 'redirectedFrom',
}

export const getSavedScroll = () => {
  const scrollPosition =
    Number(sessionStorage.getItem(sessionStorageKeys.scrollPosition)) ||
    undefined
  const page = Number(sessionStorage.getItem(sessionStorageKeys.page)) || 0
  const redirectedFrom =
    sessionStorage.getItem(sessionStorageKeys.redirectedFrom) || undefined

  return { scrollPosition, page, redirectedFrom }
}

type SaveScrollPayload = {
  page?: number
  redirectedFrom?: RedirectedFron
}

export const saveScroll = ({
  page,
  redirectedFrom,
}: SaveScrollPayload = {}) => {
  sessionStorage.setItem(
    sessionStorageKeys.scrollPosition,
    String(window.scrollY)
  )

  if (page) {
    sessionStorage.setItem(sessionStorageKeys.page, String(page))
  }

  if (redirectedFrom) {
    sessionStorage.setItem(sessionStorageKeys.redirectedFrom, redirectedFrom)
  }
}

export const restoreScroll = (redirectedFromShouldBe?: RedirectedFron) => {
  const { scrollPosition, redirectedFrom } = getSavedScroll()

  if (
    !scrollPosition ||
    isNaN(scrollPosition) ||
    (redirectedFrom && redirectedFrom !== redirectedFromShouldBe)
  )
    return new Promise(resolve => setTimeout(resolve, 1))

  return new Promise(resolve =>
    setTimeout(() => {
      window.scrollTo({ top: scrollPosition })
      clearScroll()
      resolve(true)
    }, 100)
  )
}

export const clearScroll = () => {
  sessionStorage.removeItem(sessionStorageKeys.scrollPosition)
  sessionStorage.removeItem(sessionStorageKeys.page)
  sessionStorage.removeItem(sessionStorageKeys.redirectedFrom)
}
