import { Notification } from 'api/notifications'
import classNames from 'classnames'
import { Icon } from 'components/common/Icon'
import { TooltipMenu } from 'components/common/TooltipMenu'
import { MarkAllReadButton } from 'components/notifications/MarkAllReadButton'
import { NotificationComponent } from 'components/notifications/Notification'
import routes from 'constants/routes'
import { useMarkNotificationViewed } from 'hooks/useMarkNotificationViewed'
import { useUserNotifications } from 'hooks/useUserNotifications'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { saveScroll } from 'utils/scroll'

type NotificationsContentProps = {
  notifications: Notification[]
  onRedirect?: VoidFunction
}

const NotificationsContent = ({
  notifications,
  onRedirect,
}: NotificationsContentProps) => {
  const { t } = useTranslation('notifications')
  const { viewNotifications } = useMarkNotificationViewed()

  const onClickNotification = async (n: Notification) => {
    if (!n.viewed) {
      await viewNotifications([n.id])
    }
    saveScroll({ redirectedFrom: 'homepage' })
  }

  return (
    <div>
      <div className='sticky left-0 top-0 flex w-full space-x-3 border-b border-warm-200 bg-cool-50 p-4'>
        <p className='text-xl font-medium leading-8 text-cool-900'>
          {t('common:activity')}
        </p>
        <MarkAllReadButton className='mt-1 text-xs'>
          {t('common:mark_all_as_read')}
        </MarkAllReadButton>
      </div>

      <ol className='h-full'>
        {notifications.map((notification, index) => (
          <li key={`${notification.type}@${index}`}>
            <Link
              href={routes.notifications}
              className={classNames(
                'w-full cursor-pointer',
                index !== 0 && 'border-t-2 border-primary-light'
              )}
              title='View notification'
              onClick={() => onClickNotification(notification)}
            >
              <NotificationComponent {...notification} />
            </Link>
          </li>
        ))}
      </ol>

      <div className='sticky bottom-0 left-0 flex w-full items-center justify-center border-t-2 border-primary-light bg-cool-50 py-3'>
        <Link
          href={routes.notifications}
          className='text-sm font-medium text-purple-500 underline'
          onClick={onRedirect}
        >
          {t('common:view_all')}
        </Link>
      </div>
    </div>
  )
}

interface NotificationsPopupProps {
  onRedirect?: VoidFunction
}

export const NotificationsPopup = ({ onRedirect }: NotificationsPopupProps) => {
  const { t } = useTranslation('notifications')

  const {
    data: { notifications = [], notViewedCount = 0 },
  } = useUserNotifications()

  return (
    <TooltipMenu
      content={
        <NotificationsContent
          notifications={notifications.slice(0, 6)}
          onRedirect={onRedirect}
        />
      }
      placement='bottom-left'
      contentClassName='mt-1 max-w-[26.25rem] min-w-[26.25rem] max-h-[calc(100vh-9rem)] !z-[999999] !p-0 overflow-hidden relative overflow-y-auto'
    >
      <Link
        href={routes.notifications}
        onClick={onRedirect}
        className='rounded-full p-2 transition-colors hover:bg-warm-100 flex items-center justify-center'
      >
        <span className='sr-only'>{t('common:view_notifications')}</span>

        <div className='relative'>
          {notViewedCount ? (
            <>
              <Icon
                name='filled-bell'
                filled
                className='text-cool-900'
                size={16}
              />

              <div className='absolute -right-1 -top-1 flex h-[0.875rem] w-[0.875rem] items-center justify-center rounded-full border border-cool-50 bg-danger-dark'></div>
            </>
          ) : (
            <Icon
              name='bell-2'
              className='pointer-events-none text-cool-600'
              size={16}
            />
          )}
        </div>
      </Link>
    </TooltipMenu>
  )
}
