import classNames from 'classnames'
import { useMarkNotificationViewed } from 'hooks/useMarkNotificationViewed'
import { HTMLProps, MouseEvent, useCallback } from 'react'

export function MarkAllReadButton(props: HTMLProps<HTMLButtonElement>) {
  const { viewNotifications } = useMarkNotificationViewed()

  const onClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      await viewNotifications([])
    },
    [viewNotifications]
  )

  return (
    <button
      {...props}
      type='button'
      onClick={onClick}
      className={classNames(
        'text-secondary-default underline z-[1] relative',
        props.className
      )}
    >
      {props.children}
    </button>
  )
}
