import { Icon } from 'components/common/Icon'
import { Tag } from 'components/common/Tag'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AnchorHTMLAttributes, useEffect } from 'react'
import { useDrawer } from '../../contexts'
import classNames from 'classnames'

type Props = {
  className?: string
  textClassName?: string
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
  children?: React.ReactNode
  active?: boolean
  activeTag?: boolean
  hasArrowIcon?: boolean
  mobile?: boolean
  onClick?: () => void
  customClick?: () => void
  exact?: boolean
}

export const NavItem = ({
  className,
  textClassName,
  href,
  target = '_self',
  children,
  active,
  activeTag,
  hasArrowIcon,
  mobile,
  onClick,
  customClick,
}: Props) => {
  const { setIsOpen } = useDrawer()
  const router = useRouter()

  useEffect(() => {
    if (activeTag) {
      console.log(children, activeTag)
    }
  }, [])

  const getTagIcon = () => {
    if (router.pathname.includes('/my-account/buying')) {
      return 'cart'
    }
    if (router.pathname.includes('/my-account/selling')) {
      return 'cash-money'
    }
    if (router.pathname.includes('/my-account/settings')) {
      return 'settings'
    }
  }

  const aClassName = classNames(
    'w-full flex h-10 items-center justify-between whitespace-nowrap rounded bg-transparent transition-colors duration-200 hover:bg-cool-900 hover:bg-opacity-3 hover:text-cool-800',
    textClassName,
    {
      '!font-medium !text-cool-900 [&_span]:!font-medium [&_span]:!text-cool-900':
        active || activeTag,
      'px-0 text-lg font-normal text-cool-800': hasArrowIcon,
      'px-4 text-sm font-medium text-cool-600': !hasArrowIcon,
    }
  )

  const content = (
    <>
      {children}
      {hasArrowIcon && !href ? <Icon name='arrow-chevron-forward' /> : null}
    </>
  )

  if (activeTag && !mobile) {
    return (
      <li className={className}>
        {href && (
          <Link
            href={href}
            prefetch={false}
            target={target}
            onClick={() => {
              if (customClick) customClick()
            }}
          >
            <Tag className='font-medium' icon={getTagIcon()} iconSize={16}>
              {children}
            </Tag>
          </Link>
        )}

        {!href && (
          <Tag className='font-medium' icon={getTagIcon()} iconSize={16}>
            {children}
          </Tag>
        )}
      </li>
    )
  }

  if (!href)
    return (
      <li className={className}>
        {onClick ? (
          <button type='button' onClick={onClick} className={aClassName}>
            {content}
          </button>
        ) : (
          <span className={aClassName}>{content}</span>
        )}
      </li>
    )

  return (
    <li className={className}>
      <Link
        href={href}
        prefetch={false}
        className={aClassName}
        onClick={() => {
          hasArrowIcon && setIsOpen(false)
          if (customClick) customClick()
        }}
        target={target}
      >
        {content}
      </Link>
    </li>
  )
}
